<template>
    <router-view />
</template>

<script>
	import {
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		getUserinfo,
		getSiteinfo
	} from "@/api/index";
export default {
	data(){
		
	},
	created() {
		this.getSiteinfo()
		let token = localStorage.getItem("token");
		if(token){
			this.getUserinfo()
		}
	},
	methods:{
		...mapMutations(['login','setSiteinfo']),
		async getUserinfo(){
			const loading = this.$loading({
				lock: true,
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			const res = await getUserinfo()
			if (res.code == 1) {
				this.login(res.data)
			}
			loading.close()
		},
		async getSiteinfo(){
			const res = await getSiteinfo()
			if(res.code == 1){
				this.setSiteinfo(res.data)
			}
		},
	}
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
.textoverflow{
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.link{
	cursor: pointer;
}
.el-submenu__title i{
		color: #fff!important;
	}
.el-menu.el-menu--horizontal{
	border: none!important;
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner{
	background-color: #2068b0!important;
	border-color: #2068b0!important;
}
</style>
