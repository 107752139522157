<template>
    <div class="page">
        <v-header />
        <div class="content-box">
            <div class="content">
				<router-view :key="key" v-slot="{ Component }">
					<transition name="move" mode="out-in" enter-active-class="animated slideInDown">
						<component :is="Component" />
					</transition>
				</router-view>
            </div>
        </div>
		<v-footer />
    </div>
</template>
<script>
import vHeader from "../components/Header";
import vFooter from "../components/footer";
export default {
    components: {
        vHeader,
		vFooter
    },
    computed: {
        key(){
			return this.$route.query.id
		}
    }
};
</script>
<style>
	
</style>
