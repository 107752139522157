import Vue from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '../router/index.js';
let url = '';
if (process.env.NODE_ENV == 'development') {
	url = '/';
} else if (process.env.NODE_ENV == 'production') {
	url = 'https://cfjt.xingda188.com/api/'
}
const service = axios.create({
	
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: url,
    timeout: 5000
});
// 请求超时 请求头
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
 
// 请求拦截器
axios.interceptors.request.use(
    config => {
		let token = localStorage.getItem("token");
		if(token){
			 token && (config.headers.token = token);
		}
        return config;
    },
    error => {
		console.log(error,'error');
        return Promise.error(error);
    })
 
// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code === 1) {
            return Promise.resolve(response);
        } else {
			console.log(1231231,'error.response');
			ElMessage.error(response.data.msg);
            return Promise.resolve(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
		
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                case 401:
					localStorage.removeItem('token');
					setTimeout(() => {
						router.replace({
						    path: '/login',// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
						    query: {
						        redirect: router.currentRoute.fullPath
						    }
						});
					}, 1000);
					return Promise.resolve(error.response);
                    break;
                case 403:
                    ElMessage.error({
                        message: '登录过期，请重新登录'
                    });
                    break;
                // 404请求不存在                
                case 404:
                    ElMessage.error('地址不存在');
                    break;
                // 其他错误，直接抛出错误提示                
                default:
					loading.clsoe()
                    ElMessage.error('服务器链接失败！');
            }
            return Promise.reject(error.response);
        }
    }
);
 
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params){    
    return new Promise((resolve, reject) =>{        
        axios.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {    
    return new Promise((resolve, reject) => {         
        axios.post(url, params)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
/**
 * 封装patch请求
 * @param url
 * @param params
 * @returns {Promise}
 */
export function patch(url,params){
  return new Promise((resolve,reject) => {
    axios.patch(url,params)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}
 
/**
 * put 请求
 * @param  url 
 * @param  params 
 */
export function put(url,params){
  return new Promise((resolve,reject) => {
    axios.put(url,params)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}