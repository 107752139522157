import {post,get} from '../utils/request.js'

//登录
export const login = params =>post('api/user/login',params)

//注册
export const register = params =>post('api/user/register',params)

//修改密码
export const resetpwd = params =>post('api/user/resetpwd',params)


//短信验证码
export const getCode = params =>post('api/sms/send',params)

//短信验证码
export const getUserinfo = params =>post('api/user/user_info',params)

//导航栏
export const getMenu = params =>post('api/cms/index/menu',params)

//首页数据
export const getChanneltop = params =>post('api/cms/index/channel_top',params)

//列表数据
export const getList = params =>post('api/cms/index/archives',params)

//详情
export const getDetail = params =>post('api/cms/index/detail',params)

//网站信息
export const getSiteinfo = params =>post('api/cms/index/site_info',params)

//首页边栏
export const getChannelsidebar = params =>post('api/cms/index/channel_sidebar',params)

//支付
export const orderSubmit = params =>post('api/cms/order/submit',params)

//支付状态
export const orderIspay = params =>post('api/cms/order/is_paid',params)

