import {
	createStore
} from 'vuex'
import router from '../router/index.js';
export default createStore({
	state: {
		hasLogin:false,
		userInfo:{},
		siteInfo:{}
	},
	mutations: {
		login(state,data){
			state.hasLogin = true
			state.userInfo = data
			localStorage.setItem("userInfo",JSON.stringify(state.userInfo));
			console.log(state,'state');
		},
		exit(state){
			state.hasLogin = false
			state.userInfo = {}
			localStorage.removeItem("userInfo");
			localStorage.removeItem("token");
		},
		setSiteinfo(state,data){
			state.siteInfo = data
		}
	},
	getters: {
		userInfo: state => state.userInfo,
		hasLogin: state => state.hasLogin,
		siteInfo: state => state.siteInfo
	},
	actions: {},
	modules: {}
})
