import {
	mapGetters,
	mapMutations,
	mapActions
} from 'vuex'
const baseMixin = {
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([
			'userInfo',
			'hasLogin',
			'siteInfo'
		])
	},
	methods: {
		openUrl(path){
			this.$router.push({
				path
			})
		},
		trimTime(val) {
			const check = time => time < 10 ? `0${time}` : time
			return `${val.getFullYear()}-${check(val.getMonth() + 1)}-${check(val.getDate())} ${check(val.getHours())}:${check(val.getMinutes())}:${check(val.getSeconds())}`
		},
		copyUrl(url) {
			var domUrl = document.createElement("input");
			domUrl.value = url;
			domUrl.id = "creatDom";
			document.body.appendChild(domUrl);
			domUrl.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			let creatDom = document.getElementById("creatDom");
			creatDom.parentNode.removeChild(creatDom);
			this.$message.success('复制成功!')
		},
		dateChange(val) {
			if (!val) {
				delete this.screening.start
				delete this.screening.end
			} else {
				let star = new Date(val[0])
				let end = new Date(val[1])
				this.screening.start = star.getFullYear() + '-' + this.check(star.getMonth() + 1) + '-' + this
					.check(star.getDate()) +
					' ' + this.check(star.getHours()) + '-' + this.check(star.getMinutes()) + '-' + this.check(star
						.getSeconds());
				this.screening.end = end.getFullYear() + '-' + this.check(end.getMonth() + 1) + '-' + this.check(end
						.getDate()) +
					' ' + this.check(end.getHours()) + '-' + this.check(end.getMinutes()) + '-' + this.check(end
						.getSeconds());
			}
		},
		dateChangetimestamp(val) {
			if (!val) {
				delete this.searchdata.start
				delete this.searchdata.end
			} else {
				this.searchdata.start = Math.floor(val[0].getTime() / 1000)
				this.searchdata.end = Math.floor(val[1].getTime() / 1000)
			}
			console.log(this.searchdata);
		},
		check(time) {
			let resultTime = ''
			if (time < 10) {
				resultTime = '0' + resultTime
			} else {
				resultTime = time
			}
			return resultTime;
		},
		timeSearch(daynum) {
			const that = this
			let curtime = new Date()
			let befotretime = new Date(curtime.getTime() - 3600 * 1000 * 24 * daynum)
			that.screening.start = Math.floor(befotretime.getTime() / 1000)
			that.screening.end = Math.floor(curtime.getTime() / 1000)
			that.screening.date = [new Date(befotretime.getFullYear(), befotretime.getMonth(), befotretime
				.getDate()), new Date(
				curtime.getFullYear(), curtime.getMonth(), curtime.getDate(), 23, 59, 59)]
		},
		getTime(value) {
			let date = new Date(parseInt(value) * 1000)
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
			let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
			let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
			let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
			return Y + M + D + h + m + s
		}
	}
}

export default baseMixin;

