<template>
	<div class="footer">
		<div class="copy-right">版权所有 {{siteInfo.title}} {{siteInfo.copy_right}}</div>
		<div class="other-info">
			地址：{{siteInfo.contact_address}} 技术支持：兴达科技
		</div>
	</div>
</template>
<script>
	
</script>
<style scoped lang="scss">
	.footer{
		clear: both;
		background-color: #2068b0;
		color: #fff;
		text-align: center;
		padding: 30px 0;
		font-size: 13px;
		line-height: 40px;
	}
	
</style>
