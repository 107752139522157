import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: '/',
		redirect: '/index'
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			{
				path: "/index",
				name: "index",
				meta: {
					title: '首页'
				},
				component: () => import("../views/index.vue")
			},
			{
				path: "/list",
				name: "list",
				meta: {
					title: '列表'
				},
				component: () => import("../views/list.vue")
			},
			{
				path: "/news",
				name: "news",
				meta: {
					title: '公告'
				},
				component: () => import("../views/news.vue")
			}
		]
	},
	{
		path: "/login",
		name: "Login",
		meta: {
			title: '登录'
		},
		component: () => import("../views/Login.vue")
	},
	{
		path: "/register",
		name: "register",
		meta: {
			title: '会员注册'
		},
		component: () => import("../views/register.vue")
	},
	{
		path: "/editPassword",
		name: "editPassword",
		meta: {
			title: '修改密码'
		},
		component: () => import("../views/editPassword.vue")
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 黄石城发建设内部招投标平台`;
	//const userInfo = localStorage.getItem('userInfo');
	next();
});
export default router;
