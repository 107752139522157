<template>
	<div class="header">
		<div class="logo wid1200">
			<img src="../assets/img/logo.jpg" alt="">
		</div>
		<div class="nav">
			<div class="nav-box wid1200">
				<el-menu class="sidebar-el-menu" :default-active="$route.path" mode="horizontal" popper-effect="light"
					background-color="#1876c0" text-color="#fff" active-text-color="#fff" unique-opened router>
					<el-menu-item index="/index">首页</el-menu-item>
					<template v-for="(item,index) in menus" :key="index">
						<el-menu-item v-if="item.childlist.length == 0" :index="'/news?id='+item.id + '&name='+item.name">{{item.name}}</el-menu-item>
						<el-submenu  :index="index+''" v-else>
							<template #title>
								<span>{{item.name}}</span>
							</template>
							<el-menu-item v-for="(subItem,index2) in item.childlist" :key="index2"
								:index="'/list?id='+subItem.id + '&name='+subItem.name">
								<!-- <i :class="subItem.ico"></i> -->
								<span>{{subItem.name}}</span>
							</el-menu-item>
						</el-submenu>
					</template>
				</el-menu>
			</div>
		</div>
	</div>
</template>
<script>
	import {getMenu} from '../api/index.js'
	import {
		mapGetters,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				menus: []
			};
		},
		computed: {

		},
		created() {
			this.getMenu()
		},
		methods: {
			async getMenu(){
				const res = await getMenu()
				if(res.code == 1){
					this.menus = res.data
				}
			}

		},
		mounted() {

		}
	};
</script>
<style scoped lang="scss">
	.header{
		background-color: #fff;
	}
	.logo {
		padding: 10px 0;
	}

	.nav {
		background-color: #1876c0;
		height: 60px;
	}
	
</style>
